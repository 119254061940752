body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: sans-serif;
  overflow-y: scroll;
}

.hide-input-arrows::-webkit-inner-spin-button,
.hide-input-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
